import momentz from "moment-timezone";
// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol=null) {
	// const short = `${fromSymbol}/${toSymbol}`;
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	// const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}


export function getNextDailyBarTime(barTime,resolution) {

	// Extract the milliseconds part
const milliseconds = barTime % 1000;

// Remove the milliseconds from the timestamp
const unixTimestamp = Math.floor(barTime / 1000);

// Create a moment object from the Unix timestamp
const dateTime = momentz.unix(unixTimestamp);
var newDateTime;
if (resolution === "1" || resolution === "3" || resolution === "5" || resolution === "15" || resolution === "30"
 || resolution === "60" || resolution === "120" || resolution === "240" || resolution === "720") {
    newDateTime = dateTime.seconds(0).add(Number(resolution), 'minutes');
}else if (resolution === "1D") {
	newDateTime = dateTime.seconds(0).add(Number(1), 'days');
}else if (resolution === "1W") {
	newDateTime = dateTime.seconds(0).add(Number(1), 'weeks');
}else if (resolution === "1M") {
	newDateTime = dateTime.seconds(0).add(Number(1), 'months');
}else{
	newDateTime = dateTime.seconds(0).add(Number(1), 'minutes');
}
// Get the Unix timestamp of the new date and time
const newUnixTimestamp = newDateTime.unix();

// Combine the new Unix timestamp with the original milliseconds
const newUnixTimestampWithMillis = newUnixTimestamp * 1000 + milliseconds;
return newUnixTimestampWithMillis;
}
export function getResolution(resolution) {
	let data_interval = "1m";
	if (resolution === "1") {
		data_interval = "1m";
	} else if (resolution === "3") {
		data_interval = "3m";
	} else if (resolution === "5") {
		data_interval = "5m";
	} else if (resolution === "15") {
		data_interval = "15m";
	} else if (resolution === "30") {
		data_interval = "30m";
	}else if (resolution === "60") {
		data_interval = "1h";
	} else if (resolution === "120") {
		data_interval = "2h";
	}else if (resolution === "240") {
		data_interval = "4h";
	}else if (resolution === "720") {
		data_interval = "12h";
	}else if (resolution === "1D") {
		data_interval = "1d";
	}else if (resolution === "1W") {
		data_interval = "1w";
	}else if (resolution === "1M") {
		data_interval = "1M";
	}
	return data_interval;
}

export async function getDeimalDetails() {
	return {
		"USDT": {
			"PAIR": "USDT",
			"NAME": "Tether",
			"MIN_QTY": 0,
			"MAX_MARKET_QTY": 0,
			"MAX_LIMIT_QTY": 0,
			"PRICE_DEC": 2,
			"QTY_DEC": 0
		},
		"BTCUSDT": {
			"PAIR": "BTCUSDT",
			"NAME": "Bitcoin",
			"MIN_QTY": 0.001,
			"MAX_MARKET_QTY": 120,
			"MAX_LIMIT_QTY": 1000,
			"PRICE_DEC": 1,
			"QTY_DEC": 4
		},
		"ETHUSDT": {
			"PAIR": "ETHUSDT",
			"NAME": "Etherium",
			"MIN_QTY": 0.001,
			"MAX_MARKET_QTY": 2000,
			"MAX_LIMIT_QTY": 10000,
			"PRICE_DEC": 2,
			"QTY_DEC": 3
		},
		"XRPUSDT": {
			"PAIR": "XRPUSDT",
			"NAME": "Ripple",
			"MIN_QTY": 0.1,
			"MAX_MARKET_QTY": 2000000,
			"MAX_LIMIT_QTY": 10000000,
			"PRICE_DEC": 4,
			"QTY_DEC": 0
		},
		"TRXUSDT": {
			"PAIR": "TRXUSDT",
			"NAME": "TRX",
			"MIN_QTY": 1,
			"MAX_MARKET_QTY": 5000000,
			"MAX_LIMIT_QTY": 10000000,
			"PRICE_DEC": 5,
			"QTY_DEC": 0
		}
	}
}

// Api For Load Binance Data
export async function makeApiRequestHistoryBinance(url, path) {
	const response = await fetch(`${url}/klines?${path}`);
	return response.json()
}

export function getLanguageFromURL() {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null
	  ? null
	  : decodeURIComponent(results[1].replace(/\+/g, " "));
	 
  }