import React, { useState, useEffect } from 'react'
import './Home.css'
import { TVChartContainer as TVChartStandardDeriv } from "../components/Tradingview/TVChartContainer/StandardDerivative";
import PieChart from '../Charts/PieChart'
import ColumnChart from '../Charts/ColumnChart';
import DonutChart from '../Charts/DonutChart';
import OpenInterestChart from '../Charts/OpenInterestChart'
import RecentTrade from '../components/recenttrade/RecentTrade';
import PriceTrendChart from '../Charts/PriceTrendChart';
import GaugeChart from '../Charts/GaugeChart';
import axios from "axios";
import { io } from "socket.io-client";


const Home = () => {

   const [seriesData1, setseriesData1] = useState([0]);
   const [seriesData2, setseriesData2] = useState([0]);
   const [seriesData3, setseriesData3] = useState([0]);
   const [seriesData4, setseriesData4] = useState([0]);
   const [pichartData, setPichartData] = useState([]);
   const [sentimentData, setSentimentData] = useState(0);
   const [rows, setRows] = useState([]);
   const [columnData, setColumnData] = useState([]);
   const [Trandedata, setTrandedata] = useState([]);
   const [openInterestData, setOpenInterestData] = useState([]);

   

   const colors1 = ["#0070ff"];
   const colors2 = ["#3189a5"];
   const colors3 = ["#df7800"];
   const colors4 = ["#ff4d4d"];

   const labels1 = ["BTC"];
   const labels2 = ["ETH"];
   const labels3 = ["XRP"];
   const labels4 = ["TRX"];

   function ConvertKRlocalString(number) {
      // number = parseFloat(number);
      // return number?.toLocaleString('ko-KR')
      let formatter = new Intl.NumberFormat('ko-KR', { maximumFractionDigits: 10 });
      return formatter.format(number); // Outputs: "0.5455"
   }

   useEffect(() => {
      // fetch sentiment data
      (async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/sentiment-data`, {});
            setSentimentData(response.data.fear_index);
         } catch (error) {
            console.error('Error fetching data:', error);
         }
      })();
      //socket connection
      const socket = io(process.env.REACT_APP_SERVER_URL);

      // Socket.IO event listeners
      socket.on("connect", () => {
         console.log("Socket Connected to the server");

         // Request data on connect
         socket.emit("getPichartData");
         socket.emit("getSeriesData");
         socket.emit("getRowData");
         socket.emit("getColumnData");
         socket.emit("getPriceTrendChart");
         socket.emit("getOpenInterestData");

      });

      socket.on("pieChartData", (data) => {
         setPichartData(data);
      });
      socket.on("seriesData", (data) => {
         setseriesData1([data[0]])
         setseriesData2([data[1]])
         setseriesData3([data[2]])
         setseriesData4([data[3]])
      });
      socket.on("rowData", (data) => {
         setRows(data)
      });
      socket.on("ColumnData", (data) => {
         setColumnData(data)
      });
      socket.on("PriceTrendChart", (data) => {
         setTrandedata(data)
      });
      socket.on("OpenInterestData", (data) => {
         setOpenInterestData(data)
      });

      socket.on("error", (errorMessage) => {
         console.error('Error from socket server:', errorMessage);
      });

      socket.on("disconnect", () => {
         console.log("Disconnected from the socket server");
      });

      return () => {
         socket.off("getPichartData");
         socket.off("getSeriesData");
         socket.off("getRowData");
         socket.off("getColumnData");
         socket.disconnect(); // Clean up the socket connection on component unmount
      };
   }, []);

   const factorialResult = React.useMemo(() => <GaugeChart data={sentimentData} />, [sentimentData]);
   return (
      <div className='mainWrapper'>
         <div className="headerblock">
            <h1>Mind Tree AI Trading Analysis</h1>
         </div>
         <div className="main_inner_block">
            <div className="chart_mainWrapper">
               <div className="cols_block">
                  <div className="leftchart_wrapper">
                     <div className="common_card">
                        <ColumnChart data={columnData} />
                     </div>
                     <div className="common_card  smallChart">
                        <PieChart data={pichartData} />
                     </div>
                     <div className='common_card smallChart'>
                        {factorialResult}
                     </div>

                  </div>
               </div>
               <div className="cols_block">
                  <div className="fourCharts">
                     <div className="chart_container">
                        <TVChartStandardDeriv
                           data={'BTC/USDT'}
                           theme={"Dark"}
                        />
                     </div>
                     <div className="chart_container">
                        <TVChartStandardDeriv
                           data={'TRX/USDT'}
                           theme={"Dark"}
                        />
                     </div>
                     <div className="chart_container">
                        <TVChartStandardDeriv
                           data={'ETH/USDT'}
                           theme={"Dark"}
                        />
                     </div>
                     <div className="chart_container">
                        <TVChartStandardDeriv
                           data={'XRP/USDT'}
                           theme={"Dark"}
                        />
                     </div>
                  </div>
                  <div className="progress_chart">
                     <h5>Real-time Open Postion</h5>
                     <div className="progress_chart_inner">
                        <div className="common_card">
                           <h6 style={{ color: "#1f7ef8" }}>BTC</h6>
                           <DonutChart seriesData={seriesData1} colors={colors1} labels={labels1} />
                        </div>
                        <div className="common_card">
                           <h6 style={{ color: "rgb(73 148 172)" }}>ETH</h6>
                           <DonutChart seriesData={seriesData2} colors={colors2} labels={labels2} />
                        </div>
                        <div className="common_card">
                           <h6 style={{ color: "rgb(221 133 31)" }}>XRP</h6>
                           <DonutChart seriesData={seriesData3} colors={colors3} labels={labels3} />
                        </div>
                        <div className="common_card">
                           <h6 style={{ color: "rgb(248 97 97)" }}>TRX</h6>
                           <DonutChart seriesData={seriesData4} colors={colors4} labels={labels4} />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="cols_block">
                  <div className="rightchart_wrapper">
                     <div className="recent_trade common_card">
                        <RecentTrade symbol={'BTCUSDT'} pDecimal={1} />
                        <RecentTrade symbol={'TRXUSDT'} pDecimal={5} />
                        <RecentTrade symbol={'ETHUSDT'} pDecimal={2} />
                        <RecentTrade symbol={'XRPUSDT'} pDecimal={4} />
                     </div>
                     <div className='smallChart common_card'>
                        <PriceTrendChart data={Trandedata} /> 
                     </div>
                     <div className='smallChart common_card'>
                        <OpenInterestChart height={360} data = {openInterestData}/>
                     </div>
                  </div>
               </div>
            </div>
            <div className="bottom_table_block">
               <div className="row">
                  <div className="cols_block col-12 ">
                     <div className="bottom_table_wrapper">
                        <div className="table_title">
                           <h4>Mind Tree AI Trading System</h4>
                        </div>
                        <div className="bottom_table">
                           <div className="table-responsive cm_scroller">
                              <table className='table'>
                                 <thead>
                                    <tr>
                                       <th style={{ width: "200px" }}>Contracts</th>
                                       <th className='text-center' style={{ width: "150px" }}>Order Amount</th>
                                       <th className='text-center' style={{ width: "150px" }}>Trade Type</th>
                                       <th className='text-center' style={{ width: "150px" }}>Status</th>
                                       <th className='text-end' style={{ width: "150px" }}>Order Time</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {rows.map((row, index) => (
                                       <tr key={index}>
                                          <td>{row.symbol}</td>
                                          <td>{ConvertKRlocalString(row.price)}</td>
                                          <td style={{ color: row.statusColor }}>{row.status}</td>
                                          <td>{row.activity}</td>
                                          <td>{row.dateTime}</td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   )
}

export default Home
