import * as React from 'react';
import { widget } from '../charting_library';
import Datafeed from './Derivative/StandardDeriv/datafeed';
import { getLanguageFromURL } from '../helpers';

let upColor = "#d24f45";
let downColor = "#1261c4";

// let timezone_name = timezone.tz.guess();
// const tzString = now.toLocaleString('en-US', { timeZone });
// let timezone_name = timezone.tz(zone_name).zoneName()
let timezone_name = Intl.DateTimeFormat().resolvedOptions().timeZone;
if (timezone_name === 'Asia/Calcutta') {
   timezone_name = "Asia/Kolkata";
}
export class TVChartContainer extends React.PureComponent {

   static defaultProps = {
      // symbol: 'Bitfinex:BTC/USD',
      interval: '1',
      datafeedUrl: Datafeed,
      libraryPath: './charting_library/',
      chartsStorageUrl: 'https://saveload.tradingview.com',
      chartsStorageApiVersion: '1.1',
      clientId: 'tradingview.com',
      userId: 'public_user_id',
      fullscreen: false,
      disableBeautification: false,
      autosize: true,
      studiesOverrides: {},
   };

   tvWidget = null;

   constructor(props) {
      super(props);
      this.state = { symbol: props.data, theme: props.theme }
      this.ref = React.createRef();
   }

   chartData(theme) {

      //take review from here https://static.kancloud.cn/isung/tradingview/972246
      const widgetOptions = {
         pricescale: 10,
         time_frames: [],
         time_scale: { min_bar_spacing: 1 },
         symbol: `${process.env.REACT_APP_NAME}:${this.state.symbol}`,
         // BEWARE: no trailing slash is expected in feed URL
         datafeed: Datafeed,
         interval: this.props.interval,
         container: this.ref.current,
         library_path: this.props.libraryPath,

         locale: getLanguageFromURL() || 'en',
         disabled_features: [
            'use_localstorage_for_settings', // Prevents users from saving settings locally
            'left_toolbar', // Removes the left toolbar
            'header_screenshot', // Removes screenshot functionality
            'header_saveload', // Removes save/load functionality
            'study_on_study', // Prevents applying studies on other studies
            'header_settings', // Removes settings button
            'header_undo_redo', // Removes undo/redo buttons
            'header_indicators', // Removes indicators button
            'header_compare', // Removes compare button
            'header_fullscreen_button', // Removes fullscreen button
            'timeframes_toolbar', // Removes timeframes toolbar
            'header_resolutions_toolbar',
            'header_chart_properties',
            // 'header_chart_type',
            // Add more features to disable as needed
        ],
         // disabled_features: ['use_localstorage_for_settings', "left_toolbar", "header_screenshot", "header_saveload", "study_on_study", "header_settings", "header_undo_redo", "header_indicators", "header_compare", "header_fullscreen_button", "timeframes_toolbar"], // "header_widget"->Add for remove top toolbar
         // enabled_features: ['study_templates'],
         // charts_storage_url: this.props.chartsStorageUrl,
         // charts_storage_api_version: this.props.chartsStorageApiVersion,
         client_id: this.props.clientId,
         user_id: this.props.userId,
         fullscreen: this.props.fullscreen,
         autosize: this.props.autosize,
         handleScale : false,
         handleScroll : false,
         // "theme": this.state.theme,
         theme: theme,
         timezone: timezone_name,
         // timescale_marks:false
         // studies_overrides: this.props.studiesOverrides,
         overrides: {
            // scalesProperties.textColor": "#FFF",
            'mainSeriesProperties.style': 10, // Set chart type

            "mainSeriesProperties.candleStyle.upColor": upColor,
            "mainSeriesProperties.candleStyle.downColor": downColor,
            "mainSeriesProperties.candleStyle.drawWick": true,
            "mainSeriesProperties.candleStyle.drawBorder": true,
            "mainSeriesProperties.candleStyle.borderColor": "#378658",
            "mainSeriesProperties.candleStyle.borderUpColor": upColor,
            "mainSeriesProperties.candleStyle.borderDownColor": downColor,
            "mainSeriesProperties.candleStyle.wickUpColor": upColor,
            "mainSeriesProperties.candleStyle.wickDownColor": downColor,
            "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,

            "mainSeriesProperties.vertGridProperties.color": upColor,
            "mainSeriesProperties.horzGridProperties.color": upColor,
         },
         studies_overrides: {
            "volume.volume.color.0": downColor,
            "volume.volume.color.1": upColor,
            // "volume.volume.transparency": 70,
            // "volume.volume ma.color": "#FF0000",
            // "volume.volume ma.transparency": 30,
            // "volume.volume ma.linewidth": 5,
            // "volume.show ma": true,
            // "volume.options.showStudyArguments": false,
            // "bollinger bands.median.color": "#33FF88",
            // "bollinger bands.upper.linewidth": 7
         },
      };

      const tvWidget = new widget(widgetOptions);
      this.tvWidget = tvWidget;

      tvWidget.onChartReady(() => {
         tvWidget.headerReady().then(() => {
            /*const button = tvWidget.createButton();
            button.setAttribute('title', 'Click to show a notification popup');
            button.classList.add('apply-common-tooltip');
            button.addEventListener('click', () => tvWidget.showNoticeDialog({
               title: 'Notification',
               body: 'TradingView Charting Library API works correctly',
               callback: () => {
                  console.log('Noticed!');
               },
            }));

            button.innerHTML = 'Check API';*/
            // tvWidget.activeChart().setPriceToBarRatio(70, { disableUndo: true });
            // tvWidget.activeChart().setSavingEnabled(false)
            // tvWidget.activeChart().coordinateToTime(1000);
            tvWidget.activeChart().setZoomEnabled()
            tvWidget.activeChart().canZoomOut(false)
            // tvWidget.activeChart().zoomOut()
            // tvWidget.activeChart().changePriceScale(10);
            // tvWidget.chart().getSeries().unmergeUp();
            // tvWidget.chart().getPanes()[1].setHeight(50);
         });
      });
   }

   componentDidMount() {
      this.chartData(this.state.theme);
   }

   componentDidUpdate(prevProps) {
      if (prevProps.theme !== this.props.theme) {
         this.setState({
            theme: this.props.theme,
         });
      }
      if (prevProps.symbol !== this.props.data) {
         this.setState({
            symbol: this.props.data,
         });
      }
      this.chartData(this.state.theme);
   }

   componentWillUnmount() {
      if (this.tvWidget !== null) {
         this.tvWidget.remove();
         this.tvWidget = null;
      }
   }

   render() {
      return (
         <div
            ref={this.ref}
            className={'TVChartContainer'}
         />
      );
   }
}
