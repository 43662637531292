import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import VariablePie from 'highcharts/modules/variable-pie';

// Initialize the variablepie module
VariablePie(Highcharts);

const PieChart = ({data}) => {
   const chartRef = useRef(null);

   const gradientColors = [
      {
         linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
         stops: [
            [0, '#0B5CB5'],
            [1, '#3dc3e8']
         ]
      },
      {
         linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
         stops: [
            [0, '#2dd9db'],
            [1, '#1feeaf']
         ]
      },
      {
         linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
         stops: [
            [0, '#0ff3a0'],
            [1, '#00e887']
         ]
      },
      {
         linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
         stops: [
            [0, '#23e274'],
            [1, '#0B5CB5']
         ]
      }
   ];

   const options = {
      chart: {
         type: 'variablepie',
         backgroundColor: 'rgb(19, 23, 34)',
         height:"370"
      },
      title: {
         text: 'Real-Time Crypto Buy Ratio',
         style: {
            color: '#fff',
            fontSize: '16px'
         }
      },
      tooltip: {
         headerFormat: '',
         // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> ' +
         //    '{point.name}</b><br/>' +
         //    'Area (square km): <b>{point.y}</b><br/>' +
         //    'Population density (people per square km): <b>{point.z}</b><br/>'
      },
      series: [{
         minPointSize: 10,
         innerSize: '20%',
         zMin: 0,
         name: 'countries',
         borderWidth: 0,
         data:data,
         // data: [{
         //    name: 'TRON',
         //    y: 505992,
         //    z: 92
         // }, {
         //    name: 'BTC',
         //    y: 551695,
         //    z: 119
         // }, {
         //    name: 'XRP',
         //    y: 312679,
         //    z: 121
         // }, {
         //    name: 'ETH',
         //    y: 78865,
         //    z: 136
         // }],
         colors: gradientColors
      }]
   };

   return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
};

export default PieChart;
