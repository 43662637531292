import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

// Initialize the modules
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

const GaugeChart = ({ data }) => {
  const chartRef = useRef(null);

  const options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: 'rgb(19, 23, 34)',
      height: "370"
    },

    title: {
      text: 'Market Sentiment',
      style: {
        color: "#fff"
      }
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: null,
      center: ['50%', '65%'],
      size: '105%'
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: '14px',
          color: '#fff'
        }
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: 40,
          color: '#DF5353', // red
          thickness: 20
        },
        {
          from: 40,
          to: 60,
          color: '#DDDF0D', // yellow
          thickness: 20
        },
        {
          from: 60,
          to: 100,
          color: '#55BF3B', // green
          thickness: 20
        }
      ]
    },
    series: [
      {
        name: 'Market Sentiment',
        data: [data],
        dataLabels: {
          format:
            '<div style=""><span style="font-size:25px;color:white;">{y}</span>' +
            `<span style="font-size:22px;color:#fff;"> ${data > 50 ? "Greed" : "Fear"}</span></div>`,
          borderWidth: 0,
          color: '#fff',
          style: {
            fontSize: '26px',
            textAlign: 'center',
          },
        },
        dial: {
          radius: '60%',
          backgroundColor: '#fff',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: '#fff',
          radius: 10,
        },
      },
    ],
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const chart = chartRef.current.chart;
  //     if (chart && !chart.renderer.forExport) {
  //       const point = chart.series[0].points[0];
  //       const inc = Math.round((Math.random() - 0.5) * 20);

  //       let newVal = point.y + inc;
  //       if (newVal < 0 || newVal > 200) {
  //         newVal = point.y - inc;
  //       }

  //       point.update(newVal);
  //     }
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div id="container">
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    </div>
  );
};

export default GaugeChart;
