import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import numeral from 'numeral';

const OpenInterestChart = (props) => {
   const { width, height, data } = props;

   // State to hold series data for the chart
   const [series, setSeries] = useState([
      {
         name: 'Open Interest(BTC)',
         type: 'line',
         data: [] // Initialize with empty data
      },
      {
         name: 'Trading Volume(BTC)',
         type: 'column',
         data: []
      }
   ]);

   // Effect to update series data when `data` prop changes
   useEffect(() => {
      if (data && data.length > 0) {
         const openInterestData = data.map(item => parseFloat(item.openInterest));
         const openInterestVol = data.map(item => parseFloat(item.volume));
         setSeries([
            {
               ...series[0], // Preserve other properties of the series
               data: openInterestData
            },
            {
               ...series[1], // Preserve other properties of the series
               data: openInterestVol
            },
         ]);
      }
   }, [data]);

   // Options for the ApexCharts component
   const defaultOptions = {
      chart: {
         toolbar: false,
         type: 'line'
      },
      legend: {
         labels: {
            colors: '#FFFFFF' // Change legend label color to white
         }
      },
      tooltip: {
         theme: 'dark'
      },
      colors: ['#f7a600', '#81858c'], // Open interest color first, then trading volume
      stroke: {
         width: [3, 0]
      },
      title: {
         text: 'Open Interest',
         style: {
            color: '#fff'
         }
      },
      fill: {
         type: 'solid' // This removes the gradient
      },
      dataLabels: {
         enabled: false
      },
      xaxis: {
         type: 'datetime', 
         labels: {
            datetimeUTC: false,
            datetimeFormatter: {
               year: 'yyyy',
               month: 'MMM \'yy',
               day: 'dd MMM',
               hour: 'hh:mm'
             },
            style: {
               colors: '#FFF' // Change this to your desired color
            }
         },
         axisBorder: {
            color: '#FFF' // Change this to your desired color
         },
         categories: data.map(item => item.timestamp * 1000)
      },
      grid: {
         show: false // This removes the grid lines
      },
      yaxis: [
         {
            labels: {
               formatter: function(value) {
                  return numeral(value).format('0.00a')
               },
               style: {
                  colors: '#fff' // Change this to your desired color
               }
            },
            tickAmount : 4,
            min: Math.min(...data.map(item => parseFloat(item.openInterest))),
            max: Math.max(...data.map(item => parseFloat(item.openInterest)))
         },
         {
            opposite: true,
            labels: {
               formatter: function(value) {
                  return numeral(value).format('0.00a')
               },
               style: {
                  colors: '#fff' // Change this to your desired color
               }
            },
            tickAmount : 4,
            min: 0,
            max: Math.max(...data.map(item => parseFloat(item.volume))) * 4 
         }
      ]
   };

   return (
      <Chart
         options={defaultOptions}
         series={series}
         type="line" 
         width={width !== undefined ? width : "100%"}
         height={height}
      />
   );
};

export default OpenInterestChart;
