import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const DonutChart = ({ seriesData, colors, labels }) => {
  let options = {
    chart: {
      backgroundColor: 'rgb(19, 23, 34)',
      type: "radialBar",
    },
    series: seriesData,
    colors: colors,
    plotOptions: {
      radialBar: {
        hollow: {
          size: "62%"
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -90,
            fontSize: "20px",
            show: false,
          },
          value: {
            offsetY: 5,
            color: "#fff",
            fontSize: "28px",
            show: true
          }
        }
      }
    },
    stroke: {
      lineCap: "round",
    },
    labels: labels
  };

  return <ReactApexCharts options={options} series={seriesData} type="radialBar" height={350} />;
};

export default DonutChart;
