import React, { useEffect, useState } from 'react'

const RecentTrade = ({ symbol, pDecimal }) => {
    const [allRecentTrades, setAllRecentTrades] = useState([]);

    const ChangeRecentTradeColors = (trades) => {
        let status = 'up_color'
        let r_trades = [...trades].reverse();
        r_trades?.forEach((recentTrade, key) => {
            if (key > 0) {
                if (r_trades[key]?.price < r_trades[key - 1]?.price) {
                    status = 'down_color'
                } else {
                    status = 'up_color'
                }
            }
            recentTrade.status = status;
        })
        trades = [...r_trades].reverse();
        if (trades.length >= 10) {
            trades.pop();
        }
        return trades
    }
    useEffect(() => {
        (async () => {
            const fetchedData = await fetch(`${process.env.REACT_APP_DERIV_STREAMURL}/trades?symbol=${symbol}&limit=11`);
            const data = await fetchedData.json();
            if (data) {
                let arr = [];
                for (const element of data) {
                    arr.push({
                        price: element.price,
                        qty: element.qty,
                        status: "",
                        time: element.time ? (element.time / 1000) : ''
                    })
                }
                setAllRecentTrades(ChangeRecentTradeColors(arr))
            }
        })()
    }, [symbol])

    useEffect(() => {
        // Create a new WebSocket connection when the component mounts
        const rtrade_newSocket = new WebSocket(process.env.REACT_APP_DERIV_RECENTTRADE_SOCKETURL);
        rtrade_newSocket.onopen = () => {
            rtrade_newSocket.send(JSON.stringify({
                method: "SUBSCRIBE",
                params: [
                    symbol.toLowerCase() + "@aggSnap"
                ],
                id: 2
            }));
        }
        rtrade_newSocket.onmessage = (event) => {
            let eventfata = JSON.parse(event?.data);
            if (eventfata.data) {
                let objectrectndata = eventfata.data;
                if (objectrectndata?.e === "aggSnap") {
                    let obj = {
                        price: objectrectndata?.p,
                        qty: Math.abs(objectrectndata?.cq),
                        status: objectrectndata?.cq > 0 ? 'down_color' : 'up_color',
                        time: objectrectndata?.T / 1000
                    }
                    setAllRecentTrades((prev) => {
                        let trades = [...prev]
                        if (trades.length) {
                            trades.unshift(obj)
                        } else {
                            trades.push(obj)
                        }

                        // let ftrades = [...trades].reverse();
                        if (trades.length >= 10) {
                            trades.pop();
                        }
                        return trades;
                    })
                }
            }
        }
        // Clean up function to close the WebSocket connection when the component unmounts
        return () => {
            setAllRecentTrades([])
            if (rtrade_newSocket.readyState === WebSocket.OPEN) {
                rtrade_newSocket.send(JSON.stringify({
                    method: "UNSUBSCRIBE",
                    params: [
                        `${symbol.toLowerCase()}@aggSnap`
                    ],
                    id: 2
                }));
            }
            rtrade_newSocket.close();
        }
    }, [symbol]);

    return (

        <div className="recent_trade_col">
            <h4>{symbol.replace('USDT', '')}</h4>
            <ul>
                { allRecentTrades?.length > 0 ?
                    allRecentTrades?.map((val, key) => (
                        <li key={`${val?.time}_${key}_${val?.price}`} className={`text-left ${val?.status}`}>{val?.price ? parseFloat(val?.price).toFixed(pDecimal) : ""}</li>)
                    ) : null
                }
            </ul>
        </div>


    )
}

export default React.memo(RecentTrade)