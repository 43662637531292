import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        title: {
          text: 'Hourly Crypto Buy Volume',
          style: {
            color: '#FFFFFF', // Change title color to white
            fontSize: '15px',
          }
        },
        tooltip: {
          theme: 'dark'
        },
        grid: {
          show: false // This removes the grid lines
        },
        chart: {
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        stroke: {
          width: [4, 0, 0]
        },
        xaxis: {
          categories: ["1H", "2H", "3H", "4H", "5H", "6H", "7H", "8H"],
          labels: {
            style: {
              colors: '#FFFFFF' // Change y-axis label color to white
            }
          }
        },
        markers: {
          size: 6,
          strokeWidth: 3,
          fillOpacity: 0,
          strokeOpacity: 0,
          hover: {
            size: 8
          }
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: '#FFFFFF' // Change y-axis label color to white
            }
          }
        },
        legend: {
          labels: {
            colors: '#FFFFFF' // Change legend label color to white
          }
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     shade: 'light',
        //     type: 'vertical',
        //     shadeIntensity: 0.5,
        //     gradientToColors: ['#069EFC', '#14F4C9'],
        //     inverseColors: true,
        //     borderWidth:2,
        //     opacityFrom: 1,
        //     opacityTo: 0.10,
        //     stops: [0, 100]
        //   }
        // }
      },
      responsive: [{
        breakpoint: 500,
        options: {

        },
      }],
      series: [{
        type: 'line',
        name: 'Line',
        data: props.data,
        pointPlacement: 'on'
      }, {
        type: 'bar',
        name: 'Bar',
        data: props.data,
        pointPlacement: 'on'
      }]
    };
  }

  componentDidUpdate(prevProps) {
    // Update state if props have changed
    if (prevProps.data !== this.props.data) {
      this.setState({
        series: [{
          type: 'line',
          name: 'Line',
          data: this.props.data,
          pointPlacement: 'on'
        }, {
          type: 'bar',
          name: 'Bar',
          data: this.props.data,
          pointPlacement: 'on'
        }]
      })
    }
  }

  render() {
    return (
      <div id="chart" style={{ width: '100%', maxWidth: '100%'}}>
        <ReactApexChart options={this.state.options} series={this.state.series} height={360} width="100%" />
      </div>
    );
  }
}

export default ColumnChart;
