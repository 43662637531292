import React from 'react';
import Chart from 'react-apexcharts';

const PriceTrendChart = ({data}) => {
  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        distributed: true,
      }
    },
    colors: ['#ff4000', '#ff4000', '#808080', '#00e396', '#00e396'],
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff']
      }
    },
    xaxis: {
      categories: data.map((dd) => dd.x),
      labels: {
        style: {
          colors: '#FFF' // Change this to your desired color
        }
      },
    },
    grid: {
      show: false // This removes the grid lines
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: '#FFF' // Change this to your desired color
        }
      },
    },
    tooltip: {
      theme: 'dark'
    },
    legend: {
      show: false
    },
    title: {
      text: 'Price Trend Distribution',
      align: 'left',
      style: {
        fontSize: '16px',
        color: '#fff'
      }
    },
  };

  const series = [{
    data
    // data: [
    //   { x: '>30%', y: 168 },
    //   { x: '15% ~ 0%', y: 40 },
    //   { x: '0%', y: 80 },
    //   { x: '0% ~ 15%', y: 423 },
    //   { x: '>30%', y: 68 }
    // ]
  }];

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default PriceTrendChart;
