/* eslint-disable */
import { parseFullSymbol, getResolution } from '../../../helpers';
// import _constants from '../../../../../../../_constants';
// const { COMMON } = _constants;
let ws = new WebSocket(process.env.REACT_APP_DERIV_RECENTTRADE_SOCKETURL);
const channelToSubscription = new Map();

// Socket for Binance Data
const InitializeBinanceData = (symbol,custom_resolution) => {
	// console.log("InitializeBinanceData")
	symbol = symbol == 'TRXUSDT' ? 'TRXUSDT' : symbol;
	ws.send(JSON.stringify({
		method: "SUBSCRIBE",
		params: [`${symbol.toLowerCase()}_perpetual@continuousKline_${custom_resolution}`],
		id: custom_resolution,
	}));

	ws.addEventListener('message', function (event) {
		const eventTypeStr = 0;
		const exchange = process.env.REACT_APP_NAME;

		const chartData = JSON.parse(event.data)
		if (chartData.result !== null) {

			var k = chartData?.data.k;
			const tradeTimeStr = k.t;
			const tradePriceStr = Number(k.c);
			const volumeStr = Number(k.v)

			if (parseInt(eventTypeStr) !== 0) {
				// skip all non-TRADE events
				return;
			}
			const tradePrice = parseFloat(tradePriceStr);
			const tradeTime = parseInt(tradeTimeStr);
			const volume = parseInt(volumeStr)

			let pair = chartData?.data?.ps == 'TRXUSDT' ? 'TRONUSDT' : chartData?.data?.ps;
			const channelString = `0~${exchange}~${pair}${k.i}`;
			const subscriptionItem = channelToSubscription.get(channelString);
			if (subscriptionItem === undefined) {
				return;
			}
			const lastDailyBar = subscriptionItem.lastDailyBar;
			// const nextDailyBarTime = getNextDailyBarTime(lastDailyBar?.time);
			const nextDailyBarTime = getNextDailyBarTime(lastDailyBar?.time, subscriptionItem?.resolution);
			let bar;
			if (tradeTime >= nextDailyBarTime || !lastDailyBar) {
				bar = {
					time: !lastDailyBar ? tradeTime : nextDailyBarTime,
					open: tradePrice,
					high: tradePrice,
					low: tradePrice,
					close: tradePrice,
					volume: volume
				};
			} else {
				bar = {
					...lastDailyBar,
					high: Math.max(lastDailyBar.high, tradePrice),
					low: Math.min(lastDailyBar.low, tradePrice),
					close: tradePrice,
				};
			}

			subscriptionItem.lastDailyBar = bar;

			// send data to every subscriber of that symbol
			subscriptionItem.handlers.forEach(handler => handler.callback(bar));
		}

	});
	return () => {
		ws.addEventListener("close", function () {
			ws.send(JSON.stringify({
				method: "SUBSCRIBE",
				params: [`${symbol.toLowerCase()}_perpetual@continuousKline_${custom_resolution}`],
				id: 1,
			}))
		})
	}

}

function getNextDailyBarTime(barTime) {
	const date = new Date(barTime * 1000);
	date.setDate(date.getDate() + 1);
	return date.getTime() / 1000;
}


export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
 

	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	let symbol = parsedSymbol?.fromSymbol + parsedSymbol?.toSymbol;
	let custom_resolution = getResolution(resolution)
	const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}${parsedSymbol.toSymbol}${custom_resolution}`;
	const handler = {
		id: subscribeUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscribeUID,
		resolution,
		symbol : `${parsedSymbol.fromSymbol.toLowerCase()}${parsedSymbol.toSymbol.toLowerCase()}`,
		custom_resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	
	InitializeBinanceData(symbol,custom_resolution)
}



export function unsubscribeFromStream(subscriberUID) {
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		if(subscriptionItem?.subscribeUID === subscriberUID){
			ws.send(JSON.stringify({
					method: "UNSUBSCRIBE",
					params: [`${subscriptionItem?.symbol.toLowerCase()}_perpetual@continuousKline_${subscriptionItem?.custom_resolution}`],
					id: subscriptionItem?.custom_resolution,
				}));
			channelToSubscription.delete(channelString);
			break;
		}



		// console.log("subscriptionItem",subscriptionItem,"subscriberUID",subscriberUID)
		// const handlerIndex = subscriptionItem.handlers
		// 	.findIndex(handler => handler.id === subscriberUID);

		// if (handlerIndex !== -1) {
		// 	// remove from handlers
		// 	subscriptionItem.handlers.splice(handlerIndex, 1);

		// 	if (subscriptionItem.handlers.length === 0) {

		// 		// socket.emit('SubRemove', { subs: [channelString] });
		// 		// ws.send(JSON.stringify({
		// 		// 	method: "SUBSCRIBE",
		// 		// 	params: [symbol.toLowerCase() + "@kline_1m"],
		// 		// 	id: 1,
		// 		// }));

		// 		channelToSubscription.delete(channelString);
		// 		break;
		// 	}
		// }
	}
}
